import { RouteComponentProps } from '@reach/router'
import { KIDS } from 'common/components/organisms/FundCentre/KIDS'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface KidsProps extends RouteComponentProps {
    pageContext: any
}

export const KidsPage: React.FC<KidsProps> = ({ pageContext }) => {
    return <KIDS {...getKIDSData(pageContext)} />
}

export const getKIDSData = (data: any) => {
    return {
        layout: getLayoutData(data)
    }
}

export default KidsPage
