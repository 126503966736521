import { ButtonProps } from '@material-ui/core'
import { Link } from 'common/components/atoms/Link'
import React from 'react'
import TagManager from 'react-gtm-module'
import { ReactComponent as CardCtaArrow } from './CardCtaArrow.svg'
import { CardCtaDownload } from './CardCtaDownload'
import { Button as BaseButton } from '@irishlife/ilgroupdesignsystem.atoms.button'
export interface CardCtaProps {
    label: string
    variant?: ButtonProps['variant'] | 'download'
    url?: string
    onClick?: () => void
    helpcentre?: boolean
}

export const CardCta: React.FC<CardCtaProps> = ({
    label = 'Learn more',
    variant = 'secondary',
    onClick,
    url = '',
    helpcentre,
}) => {
    if (variant === 'download')
        return <CardCtaDownload label={label} url={url} />
    return (
        <Link
            data-testid='CardCta'
            onClick={() => {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'secondaryButtonClick',
                        clickText: label,
                        clickURL: url || '',
                        clickID: label,
                    },
                })
                if (onClick) {
                    onClick()
                }
            }}
            path={url}
            helpcentre={helpcentre}
        >
            <BaseButton
                rightIcon={variant === 'text' ? <CardCtaArrow /> : undefined}
                size='md'
                variant='secondary'
            >
                {label}
            </BaseButton>
        </Link>
    )
}
