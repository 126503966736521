import React from 'react'
import { Box, Typography, Link } from '@material-ui/core'
import { ReactComponent as CardCtaPdf } from './CardCtaPdf.svg'

export interface CardCtaDownloadProps {
    label: string
    url: string
}

export const CardCtaDownload: React.FC<CardCtaDownloadProps> = ({
    label,
    url
}) => {
    return (
        <Link
            data-testid='downloadLink'
            href={url}
            style={{ textDecoration: 'none' }}
        >
            <Box
                alignItems='center'
                borderBottom={`1px solid #EBEDF7`}
                display='flex'
                height={72}
            >
                <CardCtaPdf />
                <Typography
                    style={{
                        color: '#5261AC',
                        fontSize: 16,
                        fontWeight: 600,
                        flex: 1,
                        marginLeft: 12,
                        lineHeight: 1.4
                    }}
                >
                    {label}
                </Typography>
            </Box>
        </Link>
    )
}
