import { Link } from 'common/components/atoms/Link'
import { JumbotronCallToActionProps } from 'common/components/organisms/Jumbotron/JumbotronCallToAction'
import React from 'react'
import TagManager from 'react-gtm-module'
import {
    Button as BaseButton,
    ButtonProps as BaseButtonProps,
} from '@irishlife/ilgroupdesignsystem.atoms.button'

export interface CardCtaContainedProps {
    label: string
    variant?: JumbotronCallToActionProps['variant']
    url?: string
    onClick?: () => void
    size?: BaseButtonProps['size']
    startIcon?: string
    endIcon?: string
}
export const CardCtaContained: React.FC<CardCtaContainedProps> = ({
    label = 'Learn more',
    onClick,
    url = '',
    size = 'md',
    startIcon,
    endIcon,
}) => {
    return (
        <Link
            onClick={() => {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'secondaryButtonClick',
                        clickText: label,
                        clickURL: url || '',
                        clickID: label,
                    },
                })
                if (onClick) {
                    onClick()
                }
            }}
            path={url}
        >
            <BaseButton rightIcon={undefined} size={size} variant='primary'>
                {startIcon && (
                    <img
                        alt='Left Icon'
                        src={startIcon}
                        style={{ marginRight: 10, width: 24, height: 24 }}
                    />
                )}
                {label}
                {endIcon && (
                    <img
                        alt='Right Icon'
                        src={endIcon}
                        style={{ marginLeft: 10, width: 24, height: 24 }}
                    />
                )}
            </BaseButton>
        </Link>
    )
}
