import { Box, Button, Grid, MenuItem, Select } from '@material-ui/core'
import { CardCta } from 'common/components/molecules/CardCta'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import { useDesktop } from 'common/hooks/useDesktop'
import { useKidsDocuments } from 'common/hooks/useKidsDocuments'
import { downloadKidsDocument } from 'common/utils/downloadKidsDocument'
import _ from 'lodash'
import React, { useState } from 'react'
import { Jumbotron } from '../Jumbotron'

export interface KIDSProps {
    layout: LayoutProps
}
export interface Document {
    Fund: string
    PremiumType: string
    PremiumTypeDesc: string
    ProductCode: string
    PublicFundName: string
    PublicProductName: string
    SellerCode: string
    SellerName: string
}
type DocumentFilter = Pick<
    Document,
    'PremiumTypeDesc' | 'PublicProductName' | 'SellerName' | 'PublicFundName'
>
export interface DocumentOptions {
    PremiumTypeDesc: string[]
    PublicProductName: string[]
    SellerName: string[],
    PublicFundName: string[]
}

export const getOptions = ({
    PremiumTypeDesc,
    PublicProductName,
    SellerName,
    PublicFundName
}: DocumentFilter, documents: Document[]): DocumentOptions => {
    return {
        PremiumTypeDesc: _.uniq(
            documents.map(document => document.PremiumTypeDesc).sort()
        ),
        PublicProductName: PremiumTypeDesc
            ? _.uniq(
                  _.filter(documents, {
                      PremiumTypeDesc
                  }).map(document => document.PublicProductName)
              ).sort()
            : [],
        SellerName:
            PremiumTypeDesc && PublicProductName
                ? _.uniq(
                      _.filter(documents, {
                          PremiumTypeDesc,
                          PublicProductName
                      }).map(document => document.SellerName)
                  ).sort()
                : [],
        PublicFundName:
            PremiumTypeDesc && PublicProductName && SellerName
                ? _.uniq(
                      _.filter(documents, {
                          PremiumTypeDesc,
                          PublicProductName,
                          SellerName
                      }).map(document => document.PublicFundName)
                  ).sort()
                : []
    }
}


export const KIDS: React.FC<KIDSProps> = ({ layout }) => {
    const desktop = useDesktop()
    const documents: Document[] = useKidsDocuments()

    const initialFilter = {
        PremiumTypeDesc: '',
        PublicProductName: '',
        SellerName: '',
        PublicFundName: ''
    }

    const [filter, setFilter] = useState(initialFilter)


    const getResults = (filter: DocumentFilter) => {
        if(filter.PublicFundName === '') {
            const kidsFilter = {
                PremiumTypeDesc: filter.PremiumTypeDesc,
                PublicProductName: filter.PublicProductName,
                SellerName: filter.SellerName
            }
            return _.filter(documents, kidsFilter)
        }
        else {
            return _.filter(documents, filter)
        }
    }

    const clearFilter = () => setFilter(initialFilter)

    return (
        <Layout {...layout}>
            <Jumbotron
                title='Key Information Documents'
                text={`<p>Recent legislation has led to the introduction of a Key Information Document with the aim of enabling consumers to easily compare different types of investment products offered across the EU.</p><p>The Key Information Documents are produced using a standardised EU template and approach to calculating risk, potential return and costs. They use an example investment amount of €10,000 for lump sum investments and €1,000 per annum for a regular savings plan.</p><p>If you are considering investing we recommend that you speak to your financial broker or advisor about the potential range of returns and costs that will apply to your own investment amount and product features.</p><p>You can use the search facility below to find Irish Life's Key Information Documents.</p>`}
            />
            <Spacer variant='md' />
            <Wrapper>
                <Box m={desktop ? 0 : 6} component='section'>
                    <Text
                        box={{ mb: 4 }}
                        label='Search documents'
                        variant='h4'
                        component='h1'
                    />
                    <Box
                        bgcolor='#fff'
                        borderRadius={10}
                        display='flex'
                        mb={8}
                        py={6}
                        px={6}
                        width='100%'
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                    mr={6}
                                    width='100%'
                                >
                                    <Text
                                        box={{ mb: 2 }}
                                        label='What type of product is it?'
                                        variant='body2'
                                    />
                                    <Select
                                        onChange={e =>
                                            setFilter({
                                                PremiumTypeDesc: e.target
                                                    .value as string,
                                                PublicProductName: '',
                                                SellerName: '',
                                                PublicFundName: ''
                                            })
                                        }
                                        placeholder='Select Product Type'
                                        value={filter.PremiumTypeDesc}
                                        variant='outlined'
                                    >
                                        {getOptions(filter, documents).PremiumTypeDesc.map(
                                            premiumTypeDesc => (
                                                <MenuItem
                                                    key={premiumTypeDesc}
                                                    value={premiumTypeDesc}
                                                >
                                                    {premiumTypeDesc}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                    mr={6}
                                    width='100%'
                                >
                                    <Text
                                        box={{ mb: 2 }}
                                        label='Product Name'
                                        variant='body2'
                                    />
                                    <Select
                                        onChange={e =>
                                            setFilter({
                                                ...filter,
                                                PublicProductName: e.target
                                                    .value as string,
                                                SellerName: ''
                                            })
                                        }
                                        placeholder='Product Name'
                                        value={filter.PublicProductName}
                                        variant='outlined'
                                    >
                                        {getOptions(
                                            filter,
                                            documents
                                        ).PublicProductName.map(
                                            publicProductName => (
                                                <MenuItem
                                                    key={publicProductName}
                                                    value={publicProductName}
                                                >
                                                    {publicProductName}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                    mr={6}
                                    width='100%'
                                >
                                    <Text
                                        box={{ mb: 2 }}
                                        label='Who is your Advisor'
                                        variant='body2'
                                    />
                                    <Select
                                        onChange={e =>
                                            setFilter({
                                                ...filter,
                                                SellerName: e.target
                                                    .value as string
                                            })
                                        }
                                        placeholder='Select Advisor'
                                        value={filter.SellerName}
                                        variant='outlined'
                                    >
                                        {getOptions(filter, documents).SellerName.map(
                                            sellerName => (
                                                <MenuItem
                                                    key={sellerName}
                                                    value={sellerName}
                                                >
                                                    {sellerName}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </Box>
                            </Grid>
                            <Box flex={1} />
                            <Box
                                display='flex'
                                flexDirection='column'
                                style={{ marginTop: 30 }}
                            >
                                <Button
                                    fullWidth
                                    onClick={clearFilter}
                                    variant='contained'
                                    style={{
                                        border: 'none',
                                        height: 54,
                                        textTransform: 'unset'
                                    }}
                                >
                                    Clear
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                    {getResults(filter).map((item: any) => {
                        return (
                            <Box
                                key={`${item.PublicFundName} / ${item.SellerCode}`}
                                style={{
                                    background: '#fff',
                                    borderRadius: 10,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    margin: '10px 0px',
                                    padding: '15px 20px 15px 20px'
                                }}
                            >
                                <Box>
                                    <Text
                                        box={{ mb: 2 }}
                                        label={item.PublicFundName}
                                        variant='h6'
                                    />
                                    <Text
                                        color='textSecondary'
                                        label={`${item.PremiumTypeDesc} / ${item.PublicProductName} / ${item.SellerName} / ${item.SellerCode}`}
                                        variant='caption'
                                    />
                                </Box>
                                <CardCta
                                    label='View PDF'
                                    onClick={() => downloadKidsDocument(item)}
                                />
                            </Box>
                        )
                    })}
                </Box>
            </Wrapper>
        </Layout>
    )
}
